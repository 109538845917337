.AllQuizHeading {
    display: flex;
    justify-content: space-between;
}

.allQuizTitle {
    font-size: 13px;
    font-weight: 600;
}

.AllQuizHeading .left {
    flex: 1;
}

.AllQuizHeading .right {
    flex: 1;
}

.AllQuizHeading .right .content {
    display: flex;
    justify-content: space-between;
    float: right;
}

.AllQuizHeading .right .content .ant-input-affix-wrapper {
    flex: 1;
}

.AllQuizHeading .right .content .sortby {
    flex: 1;
    margin-left: 10px;
}

.allQuizSort {
    font-weight: 600;
    font-size: 14px;
    color: gray;
}

.allQuizTable {
    margin-top: 10px;
}

.allQuizheading {
    background-color: #f9f9f9;
}

.allQuizheading th {
    font-size: 13px;
}

.allQuizTableStartbtn {
    background-color: #0fbb3f;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

.failPill {
    background-color: rgba(255, 0, 0, 0.452);
    color: #000;
    font-size: 12px;
    padding: 2px 15px;
    border-radius: 10px;
    font-weight: 600;
}

.passPill {
    background-color: #0fbb407e;
    color: #000;
    font-size: 12px;
    padding: 2px 15px;
    border-radius: 10px;
    font-weight: 600;
}

.nillPill {
    background-color: #f3e30481;
    color: #000;
    font-size: 12px;
    padding: 2px 15px;
    border-radius: 10px;
    font-weight: 600;
}

.detailsQuizOverview {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detailsQuizOverview .title {
    font-size: 13px !important;
}

.detailsQuizOverview .subtitle {
    font-size: 13px !important;
    font-weight: 300;
}

.iconcheked {
    color: #0fbb3f;
    margin-right: 5px;
}

.iconClosed {
    color: #e52828;
    margin-right: 5px;
}

.detailsQuizOverview span {
    color: #121f3e;
    margin-right: 5px;
    font-weight: 600;
    font-size: 13px;
}

.detailsQuizOverview .persent {
    color: #121f3e;
    margin-right: 5px;
    font-weight: 300;
    font-size: 15px;
}

.detailsQuizOverviewContent {
    display: flex;
    justify-content: space-between;
}

.detailsQuizOverviewContent th {
    color: #121f3e;
    font-weight: 600;
    font-size: 13px;
}

.detailsQuizOverviewContent td {
    color: #121f3e;
    padding-left: 10px;
    padding-right: 5px;
    font-weight: 300;
    font-size: 14px;
}

.detailsQuizQuestoins {
    margin-top: 10px;
}

.detailsQuizQuestoins .ant-radio-wrapper {
    display: block;
}

.detailsQuizQuestoins .ant-checkbox-wrapper {
    display: block;
}

.detailsQuizQuestoinsHr .ant-radio-wrapper {
    display: inline-block !important;
}

.detailsQuizQuestoinsHr .ant-checkbox-wrapper {
    display: inline-block !important;
}

.detailsQuizQuestoins .ant-checkbox {
    display: inline-block;
}

.detailsQuiz .more {
    text-align: center;
}

.viewcertificaate {
    margin-top: 20px;
    margin-bottom: 40px;
    border: none;
    padding: 6px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 600;
}

.quizTime {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}

.quizTime .title {
    font-weight: 600;
    font-size: 14px;
}

.quizTime .subtitle {
    font-weight: 300;
    font-size: 14px;
    margin-right: 15px;
}

.detailsQuiz .pagination {
    display: flex;
    justify-content: space-between;
}

.detailsQuiz .pagination .paginationbtn {
    border: none;
    border-radius: 5px;
    padding: 6px 10px;
    text-transform: capitalize;
    cursor: pointer;
}

.detailsQuizQuestoinsList {
    margin-top: 10px;
    list-style: none;
    padding: 0px;
}

.detailsQuizQuestoinsList li {
    margin-top: 5px;
}

.bgc-submit {
    background-color: #0fbb3f;
    color: #fff;
}

.detailsQuizQuestoinsTextArea {
    border: none;
    background-color: #f9f9f9;
    margin-top: 15px;
}

.text-user-answer {
    border: 1px solid #ddd;
    padding: 5px;
    background-color: #f9f9f9;
    border-radius: 5px;
    min-height: 2em;
}

.ant-checkbox-group .ant-checkbox-group-item {
    width: 100% !important;
    margin: 5px 0px !important;
}