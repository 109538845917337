.video-details {
  display: flex;
  justify-content: space-between;
}
.video-details .videoLeft {
  flex: 2;
  overflow-y: scroll;
}
.video-details .videoLeft .player {
  width: 100%;
  height: 445px;
}
.video-details .videoRight {
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  position: sticky;
  top: 78px;
  padding: 0px 10px;
}
.video-details .videoLeft .description {
  padding-top: 15px;
  margin-bottom: 0px;
}
.video-details .videoText .videotitle {
  margin-top: 5px;
  line-height: 20px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.description .videosTimeing .videoUploadTime {
  color: #00000073;
  font-size: 12px;
  font-weight: 600 !important;
}
.description .videosTimeing .videoViews {
  color: #00000073;
  font-size: 12px;
  font-weight: 600 !important;
}
.videoText .text {
  font-weight: 400 !important;
  color: #000000;
}
.videoTags {
  margin-bottom: 15px;
}
.videoTags .ant-tag {
  background-color: rgb(223, 221, 221) !important;
  font-weight: 600 !important;
  border: 1px solid rgb(161 161 161) !important;
}
.videoTags .tagsTitle {
  text-transform: capitalize;
  margin-right: 5px;
}

.video-details .videoRight .related-video-list .thumbnails img {
  width: 100%;
  height: 85px !important;
  border-radius: 5px;
}
