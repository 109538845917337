.filterDivision{
    margin-bottom: 10px;
    margin-top: 20px;
}
.eFilterTitle{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
    display: block;
}
.filterDivision .ant-checkbox-group-item{
    margin-bottom: 7px;
}
.elearnigFilter{
    position: sticky;
    top: 75px;
}