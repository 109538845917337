.elearnigMyCourseDetails {
  margin: 5px 0px 30px;
}
.elearnigMyCourseDetailsPostedTime {
  font-weight: 600;
  font-size: 13px;
  color: #8d8787;
  margin-top: 10px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.elearnigMyCourseDetailsTitle {
  font-weight: 600;
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.elearnigMyCourseDetailsDesc {
  font-size: 14px;
  color: #8d8787;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.elearnigMyCourseDetailsTabs {
  margin-top: 35px;
}
.elearnigMyCourseDetailsTabs .ant-tabs-tab-btn {
  text-transform: capitalize;
  font-weight: 500;
}
.elearnigMyCourseDetailsSubTitle {
  margin-top: 25px;
  font-size: 14px !important;
  text-transform: capitalize;
}
.elearnigMyCourseDetailsText {
  color: #373636;
}
.elearnigMyCourseDetailsList {
  margin-top: 15px;
}
.elearnigMyCourseDetailsItem {
  margin-bottom: 10px;
}
.elearnigMyCourseDetailsSubTitle2 {
  font-weight: 600;
  font-size: 12px;
  color: #8d8787;
}
.elearnigMyCourseDetailsThumbnails {
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
}
.elearnigMyCourseDetailsThumbnails .img {
  width: 200px;
  height: 180px;
}
.elearnigMyCourseDetailsThumbnails .img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.elearnigMyCourseDetailsThumbnails .list {
  list-style: none;
  margin: 0;
}
.elearnigMyCourseDetailsThumbnails .listItem {
  margin-bottom: 10px;
  font-weight: 500;
}

.showForm {
  display: block;
}

.hideForm {
  display: none;
}
