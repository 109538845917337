.callHandleTitle {
  font-size: 15px !important;
  text-transform: capitalize;
}
.callHandleDetailsQuestions .questionsList {
  margin: 0px;
  padding: 0;
  list-style: none;
}
.questionsListItems .noOfQ {
  font-size: 14px !important;
}
.questionsListItems .questions {
  font-size: 15px !important;
  margin-top: 10px;
}
.questionsListItems .qOptions .ant-radio-wrapper {
  margin-top: 5px;
  margin-bottom: 5px;
}
.click_image {
  width: 30%;
  height: auto;
}
.bg-red {
  background-color: #fde6e6 !important;
}
