.elearnigAllCoursesDivisionArea {
  margin-top: 30px;
}
.elearnigAllCourseTitle {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}
.elearnigAllCoursesResults {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}
.elearnigAllCoursesResultsCount {
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
  color: #8d8787;
}
.elearnigAllCoursesortby {
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
  color: #8d8787;
}
.featuredDivider {
  margin: 5px 0px 20px !important;
}

.elearningCoursesDetailsBannerCard {
  width: 100%;
}

.more-btn {
  text-align: center;
  margin-bottom: 1em;
}

.image-fluid {
  width: 100% !important;
  height: 450px !important;
}

.content-display {
  line-height: 2em;
}

.react-pdf__Page__canvas {
  width: 100% !important;
}

.dkpQuizStart {
  margin: auto;
  width: 50%;
  padding: 10px;
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
}
