.reportheading {
    background-color: rgba(0, 0, 0, 0.075);
    align-items: center;
}

.reportTitle {
    font-size: 13px;
    font-weight: 600;
}

.reportheading th {
    font-size: 13px;
    text-transform: capitalize;
}

.reportTable .table td {
    font-size: 13px;
}

.reportTable {
    margin-top: 10px;
}

.reportTableArea .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.float-right {
    float: right;
}