.elearnigAllCoursesDivisionArea {
  margin-top: 30px;
}
.elearnigAllCourseTitle {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}
.elearnigAllCoursesResults {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}
.elearnigAllCoursesResultsCount {
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
  color: #8d8787;
}
.elearnigAllCoursesortby {
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
  color: #8d8787;
}
.featuredDivider {
  margin: 5px 0px 20px !important;
}

.elearningCoursesDetailsBannerCard {
  width: 100%;
}

.more-btn {
  text-align: center !important;
  margin-bottom: 5em !important;
}

.ge-card {
  background: #fff;
  border-radius: 5px;
}

.margin-5 {
  margin: 15px 5px;
}

.margin-10 {
  margin-top: 10px;
}
