.certificate {
  padding-bottom: 30px;
}
.certificate .certificateHeading {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
}
.certificate .certificateHeading .title {
  margin-bottom: 0;
  font-size: 14px !important;
}
.certificate .mainBody {
  display: flex;
  justify-content: space-between;
}
.certificate .mainBody .certificateBody {
  flex: 1;
}
.certificate .mainBody .certificateBody .frame {
  background-color: #fff;
  border-radius: 10px;
  /* height: 100%;
  width: 75%; */
  /* height: 107%;
  width: 85%; */
  width: 85%;
  margin-right: 20%;
  /* margin: auto; */
}

.position-abs {
  position: absolute;
}
.certificateBody .frame img {
  width: 200px;
  margin-top: 20px;
}
.certificate .mainBody .certificateinfo {
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgb(44 40 40 / 7%);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.certificateinfo .info {
  padding: 5px 20px;
}
.certificateinfo .info h2 {
  font-size: 14px !important;
}
.certificateBody .frame .borderArea {
  /* padding: 30px; */
  padding: 27px 33px 0px 31px;
}
.certificateBody .frame .contents {
  padding: 60px;
  text-align: center;
  /* border-style: double; */
}
.certificateBody .frame .borderArea .title {
  margin-top: 30px;
}
.certificateBody .frame .borderArea .complete {
  margin-top: 50px !important;
}
.certificateBody .frame .borderArea .successmeg {
  margin: 50px auto !important;
  margin-bottom: 70px !important;
  font-weight: 400 !important;
  font-style: italic;
}
.certificateBody .frame .borderArea .courseName {
  margin-top: 40px !important;
  font-size: 15px !important;
}
.certificateBody .frame .borderArea .courseGrade {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
  font-size: 15px !important;
}
.certificateBody .frame .borderArea .footerArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 130px;
  margin-right: 60px;
}
.certificateBody .frame .borderArea .footerArea .autor {
  text-align-last: left;
}
