.search-page {
  margin-bottom: 50px;
}
.search-page .search-area .col-sm-3 {
  padding: 0;
}
.search-page .search-area .col-sm-9 {
  padding: 0;
}
.search-page .search-area .card {
  padding: 5px 20px;
  margin-top: 50px;
  border: 1px solid #00c8ff5b !important;
}
.search-page .searh-select-field {
  display: inline-block;
  width: 100%;
}
.search-page .searh-select-field .ant-select-selector {
  height: 40px;
  border: none;
  box-shadow: none !important;
}
.search-page .searh-text-field .ant-input-group input {
  height: 40px;
  border: none;
}
.search-page .searh-text-field {
  display: inline-block;
  width: 100%;
}
.search-page .searh-text-field button:hover {
  background-color: #5b12a5;
  color: #fff !important;
}
.search-page .searh-text-field button {
  background-color: #00c8ff;
  border: #00c8ff;
  border-radius: 50%;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  box-shadow: none !important;
}
.searchVideoResults .related-video-list .thumbnails {
  width: 15% !important;
}
.searchVideoResults .related-video-list .thumbnails img {
  width: 100%;
  height: 80px;
}
.searchLoadMore {
  border: none;
  font-weight: 400;
  border-radius: 2px;
  padding: 5px 15px;
  background-color: #e6e5e5;
}
.searchLoadMore:hover {
  background-color: rgb(223, 221, 221);
  transition: 0.4s;
}
.searchContentResultCounting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.searchContentResultCounting .totalResults {
  font-weight: 600;
  font-size: 13px;
  color: gray;
}
.searchContentResultCounting .resultsSort {
  font-weight: 600;
  font-size: 13px;
  color: gray;
  margin-right: 10px;
}
.searchContent .resultContent {
  padding: 0px 10px;
  position: relative;
}
.searchContent .resultContent .resultTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.searchContent .resultContent .resultContents {
  color: #686565;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.searchContent .resultContent .resultsBookmarks {
  position: absolute;
  top: 0;
  right: 0;
}

.searchContent .resultContent .resultsTagTimes {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
