.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #00c8ff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #00c8ff;
}
.ant-menu-submenu-selected {
  color: #00c8ff !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #f7f4f4;
  border-radius: 10px;
  font-weight: 600;
}
