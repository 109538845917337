.banner-carousel-area {
  margin-bottom: 30px;
}
.banner-carousel-area img {
  width: 100%;
}

.banner-carousel-area .swiper-container {
  width: 100%;
  height: 100%;
}

.banner-carousel-area .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.banner-carousel-area .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-carousel-area .swiper-button-next:after {
  color: #fff;
}
.banner-carousel-area .swiper-button-prev:after {
  color: #fff;
}
.banner-carousel-area .swiper-pagination .swiper-pagination-bullet {
  color: #fff !important;
  background-color: #fff !important;
}
.sliderImg {
  width: 100% !important;
  /* height: 400px !important; */
  object-fit: cover !important;
}
