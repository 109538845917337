.topbar-component {
  display: inline-block;
  flex: 1;
}
.topbar-component .topbar-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.topbar-wrapper .top-right {
  padding-right: 20px;
}
.topbar-component .topbar-wrapper .searchButtonTopbar a span {
  color: #121f3e;
  font-weight: 300 !important;
  font-size: 13px;
}
.topbar-component .topbar-wrapper ul > li a span {
  color: #121f3e;
  font-weight: 600 !important;
  font-size: 13px;
}
.topbar-component .topbar-wrapper ul > li a span:hover {
  color: #00c8ff;
}
.topbar-component .topbar-wrapper ul > li {
  margin: 0px 15px;
}
.searchButtonTopbar button {
  background-color: rgba(243, 240, 240, 0.712);
}
.topbar-wrapper .top-right .ant-avatar {
  width: 32px !important;
  height: 32px !important;
  border: 2px solid #aaa !important;
  margin: 0px 10px;
}
.ant-dropdown-menu {
  /* width: 180px !important; */
  padding: 10px !important;
}
.bellButtonUrl .ant-btn {
  border: none;
  padding: 2px;
  height: 28px;
}
.notificationsDown {
  width: 300px !important;
  max-height: 350px;
  overflow: auto;
}
/* .notificationsDown li {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
} */
.seeAllNotificationsUrl {
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}
.seeAllNotificationsUrl:hover {
  color: #00c8ff !important;
}

.searchButtonTopbar .ant-input-affix-wrapper {
  border-radius: 5px;
  padding: 7px 12px;
  background-color: #f6f6f6;
  border: none;
}
.searchButtonTopbar input {
  padding: 1px 10px !important;
  background-color: #f6f6f6;
}
.loginAs .name {
  font-weight: 600;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  white-space: inherit !important;
}
