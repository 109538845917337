.confusionBoxBody {
  margin: 30px 0px;
  padding-bottom: 30px;
}
.confusionBoxBody .boxTitle {
  font-size: 14px;
  color: #121f3e;
  font-weight: 600;
  float: left;
}
.confusionBoxBody .ant-divider {
  margin-top: 5px;
  margin-bottom: 10px;
}
.confusionBoxPostButton {
  border: none;
  background-color: #00c8ff;
  color: #fff;
  border-radius: 5px;
  padding: 4px 30px;
}
.confusionBoxBody .boxQuestion {
  text-align: right;
}
.confusionBoxBody .boxQuestion .card {
  padding-bottom: 30px;
}
.confusionBoxBody .boxQuestion .card .ant-input {
  border: none;
}
.confusionBoxBody .boxQuestion .card .uploadFile {
  width: 40px;
  display: inline-block;
  position: absolute;
  margin: auto;
  left: 15px;
  bottom: 5px;
}
.confusionBoxBody .boxQuestion .card .uploadImage {
  width: 40px;
  display: inline-block;
  position: absolute;
  margin: auto;
  left: 50px;
  bottom: 5px;
}
.confusionBoxBody .boxQuestion .card svg {
  font-size: 30px;
  color: #68686b !important;
}
.confusionBoxBody .boxQuestion .card .ant-btn {
  border: none;
}
.confusionBoxDetails {
  margin-top: 30px;
}
.confusionBoxDetails .boxDetailsFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confusionBoxDetails .boxDetailsFlex .boxTitle {
  text-transform: capitalize;
  margin-right: 5px;
}
.confusionBoxResults {
  margin-top: 30px;
}
.confusionBoxResults .ant-list-item {
  justify-content: flex-start !important;
  border: none !important;
  flex-direction: initial;
  display: flex;
}
.confusionBoxResults .ant-list-item .img {
  width: 20%;
  padding: 0px 10px;
}
.confusionBoxResults .ant-list-item .img .ant-avatar {
  width: 100%;
  height: 120px;
  border-radius: 5px !important;
}

.confusionBoxResults .content-des {
  width: 80%;
  padding-left: 10px;
}
.confusionBoxResults .content-des .postTime {
  display: block;
  font-weight: 600;
  color: #8d8787;
  font-size: 13px;
}

.confusionBoxResults .content-des .postTitle {
  display: block;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.confusionBoxResults .content-des .description {
  display: block;
  color: #8d8787;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.loardMore {
  border: none !important;
  width: 120px;
  display: block;
  margin: auto;
  background-color: #dadbdd;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #2196f3;
  padding: 7px 10px;
}

/* //DetailsConfusions */
.detailsConfusions {
  padding-bottom: 40px;
}
.detailsConfusionsBody .title .detailsConfusionsTitle {
  text-transform: capitalize;
  font-weight: 600;
}
.detailsConfusionsBody .title .ant-divider {
  margin-top: 5px;
  margin-bottom: 25px;
}
.detailsConfusionsBody .listBanner {
  margin-bottom: 30px;
}
.detailsConfusionsBody .listBanner .ant-list-item .img {
  width: 20%;
  padding: 0px 10px;
}
.detailsConfusionsBody .listBanner .ant-list-item .img .ant-avatar {
  width: 100%;
  height: 120px;
  border-radius: 5px !important;
}
.detailsConfusionsBody .listBanner .content-des {
  width: 80%;
}
.detailsConfusionsBody .listBanner .ant-list-item {
  justify-content: flex-start !important;
  border: none !important;
  flex-direction: initial;
}

.detailsConfusionsBody .listBanner .content-des {
  width: 80%;
}
.detailsConfusionsBody .listBanner .content-des .postTime {
  display: block !important;
  font-weight: 600;
  color: #8d8787;
  font-size: 13px;
}

.detailsConfusionsBody .listBanner .content-des .postTitle {
  display: block;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.detailsConfusionsBody .listBanner .content-des .description {
  display: block;
  color: #8d8787;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.detailsConfusionsBody .listItems .ant-list-item {
  border: none;
}

.detailsConfusionsBody .listItems .ant-list-item-meta-title {
  margin-bottom: 0px !important;
}
.detailsConfusionsBody .listItems .ant-list-item-meta-title a {
  font-weight: 500;
}

.detailsConfusionsBody .listItems .ant-list-item-meta-description span {
  font-weight: 400;
  color: #8d8787;
  font-size: 13px;
}
.detailsConfusionsBody .textboxListItem .ant-input {
  border: none;
  background-color: #f9f9f9;
  border-radius: 10px;
}
.detailsConfusionsBody .textboxListItem .ant-list-item {
  justify-content: initial;
}
.detailsConfusionsBody .textboxListItem .ant-list-item .textbox {
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 10px;
  position: relative;
  margin-left: 15px;
}
.detailsConfusionsBody .textfiles .ant-upload svg {
  font-size: 20px;
}
.detailsConfusionsBody .textfiles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.detailsConfusionsBody .textfiles .left {
  margin-left: 50px;
}
.detailsConfusionsBody .textfiles .left .confusionBoxFileUpload {
  display: inline-block;
}
.detailsConfusionsBody
  .textfiles
  .left
  .confusionBoxFileUpload
  .ant-upload
  .ant-btn {
  border: none;
  background-color: #fafafc;
  box-shadow: none;
}
.detailsConfusionsBody
  .textfiles
  .left
  .confusionBoxFileUpload
  .ant-upload
  .ant-btn
  svg {
  font-size: 25px;
  color: #68686b;
}
.detailsConfusionsBody
  .textfiles
  .left
  .confusionBoxImgUpload
  .ant-upload
  .ant-btn {
  border: none;
  background-color: #fafafc;
  box-shadow: none;
}
.detailsConfusionsBody
  .textfiles
  .left
  .confusionBoxImgUpload
  .ant-upload
  .ant-btn
  svg {
  font-size: 25px;
  color: #68686b;
}
.detailsConfusionsBody .textfiles .left .confusionBoxImgUpload {
  display: inline-block;
}
.detailsConfusionsBody .textfiles .right .ant-btn {
  background-color: #00c8ff;
  border-radius: 10px;
  color: #fff;
}
.detailsConfusionsBody .textfiles .right .ant-btn:hover {
  color: #fff !important;
}
.detailsConfusionsBody .textfiles .right button svg {
  color: #00c8ff;
  font-size: 30px;
}
.confusionBoxActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confusionBoxActions .ant-upload .ant-btn {
  border-radius: 10px;
}
