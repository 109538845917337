.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00c8ff !important;
  border-color: #00c8ff !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #00c8ff !important;
}
.trigger:hover {
  color: #00c8ff;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #00c8ff !important;
  border-color: #00c8ff !important;
}
.ant-list-item-meta-title > a:hover {
  color: #00c8ff !important;
}
/* .site-layout{
  overflow-y: scroll !important;
} */
.ant-layout-sider-children {
  height: 100vh;
  overflow-y: scroll;
  position: sticky;
  top: 0;
}
/* width */
.ant-layout-sider-children::-webkit-scrollbar {
  width: 4px;
  border-radius: 15px;
}

/* Track */
.ant-layout-sider-children::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.ant-layout-sider-children::-webkit-scrollbar-thumb {
  background: rgb(240, 238, 238);
}

/* Handle on hover */
.ant-layout-sider-children::-webkit-scrollbar-thumb:hover {
  background: rgb(218, 216, 216);
}
.ant-layout {
  background-color: #fafafc !important;
}
.logo {
  width: 40%;
  height: auto;
  margin: 15px auto;
}
.logo img {
  width: 100%;
  height: 100%;
}
.site-layout .site-layout-header {
  background: #fff;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 7%);
  z-index: 3;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
}
.site-layout .site-layout-background {
  background: #dbdbdb;
  padding: 15px;
  overflow: auto;
}

/* sidebar  */

.ant-layout-sider {
  background-color: #fff !important;
  box-shadow: 0 3px 6px rgb(44 40 40 / 7%);
  z-index: 1;
}
.sider-menu {
  background-color: #fff !important;
  margin: 30px auto !important;
  padding: 0px 10px;
}
.sider-menu .ant-menu-item {
  margin: 0px !important;
  text-transform: capitalize;
  border-radius: 10px;
}
.sider-menu .ant-menu-item-selected {
  background-color: #00c8ff !important ;
}
.sider-menu .ant-menu-item-selected .ant-menu-title-content::after {
  border-right: 3px solid #00c8ff !important ;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #00c8ff !important ;
}
.ant-menu-item-selected {
  color: #fff !important;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #001529 !important;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #001529 !important;
}
.ant-menu .ant-menu-item-selected .ant-menu-title-content a {
  color: #fff !important;
  font-weight: 600;
}
.ant-menu .ant-menu-item .ant-menu-title-content a {
  color: #001529;
}
.ant-menu .ant-menu-item .ant-menu-title-content a:hover {
  color: #00c8ff;
  text-decoration: none;
}
.ant-tooltip-inner {
  background-color: #fff;
}
.ant-tooltip-inner a {
  color: #001529 !important;
}
.sider-menu .ant-menu-sub {
  padding-left: 22px;
  border-left: 2px solid rgb(223, 222, 222);
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 3px 10px !important;
}
