.elarnigAllCoursCard {
  padding: 10px 25px !important;
}
.elarnigAllCoursCardContents {
  display: flex;
  margin: 5px 10px;
}
.elarnigAllCoursCardLeft {
  flex: 1;
}
.elarnigAllCoursCardThumbnails {
  height: 250px;
  width: 400px;
  position: relative;
  background-color: #000;
  border-radius: 10px;
}
.elarnigAllCoursCardThumbnails img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  opacity: 0.8;
}
.elarnigAllCoursCardThumbnails img:hover {
  opacity: 1;
}
.elarnigAllCoursCardPlaybtn {
  font-size: 45px;
  color: #d3d3d4;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.elarnigAllCoursCardRight {
  flex: 2;
  margin: 5px 18px;
}
.elarnigAllCoursCardTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.elarnigAllCoursCardDesc {
  color: #8d8787;
  font-size: 14px !important;
  padding-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.elarnigAllCoursCardUpTime {
  color: #8d8787;
  font-size: 14px !important;
  font-weight: 600;
  padding-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.elarnigAllCoursCardInstructor {
  color: #8d8787;
  font-size: 14px !important;
  font-weight: 600;
  padding-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.elarnigAllCoursCardReview {
  margin-bottom: 5px;
  display: block;
}
.elarnigAllCoursCardReview svg {
  width: 0.8em;
}
.elearnigReviewTitle {
  font-size: 14px;
  margin-right: 10px;
}

.elarnigAllCoursCardRight .info .list {
  list-style: none;
  padding: 0;
}

.elarnigAllTrainerCardThumbnails {
  position: relative;
  background-color: #000;
  border-radius: 10px;
  width: 200px;
  height: 200px;
}

.elarnigAllTrainerCardThumbnails img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  opacity: 0.8;
}
.elarnigAllTrainerCardThumbnails img:hover {
  opacity: 1;
}

.elarnigCoursCardThumbnails {
  height: 200px;
  width: 150px;
  position: relative;
  background-color: #000;
  border-radius: 10px;
}
.elarnigCoursCardThumbnails img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  opacity: 0.8;
}
.elarnigCoursCardThumbnails img:hover {
  opacity: 1;
}
