.howToActivate .ant-tabs {
  display: initial !important;

  position: relative;
}

/* test */

.howToActivate .ant-tabs .howToActivateAction {
  position: absolute;

  z-index: 999;

  margin: auto !important;

  left: 30px;

  top: -45px !important;

  font-size: 15px !important;

  font-weight: 600 !important;
}

.howToActivate .ant-tabs-nav {
  width: 30%;

  display: inline-block !important;

  background-color: #fff;

  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 7%);

  border-radius: 10px;

  padding: 30px;
}

.howToActivate .ant-tabs-content-holder {
  width: 60%;

  display: inline-block !important;

  margin-left: 30px;

  text-align: center;
}

.howToActivate .ant-tabs-nav-list {
  display: block !important;
}

.howToActivate .ant-tabs-nav-list .ant-tabs-tab {
  display: block !important;

  margin: 0 !important;
}

.howToActivate .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.howToActivate .ant-tabs-ink-bar {
  display: none;
}

.howToActivate .ant-tabs-tab-btn {
  color: #2196f3;
}

.howToActivate .ant-tabs-content-holder .content .mainFrame {
  width: 600px;

  margin: auto;
}

.howToActivate .ant-tabs-content-holder .ant-tabs-content {
  display: initial !important;
}

.howToActivate .ant-tabs-content-holder .ant-tabs-content #rc-tabs-0 {
  position: absolute;

  top: -65px;
}

.howToActivate .ant-tabs-content-holder .ant-tabs-content .ant-image {
  width: 400px;

  height: 600px;
}

.howToActivate .ant-tabs-content-holder .ant-tabs-content .ant-image-img {
  height: 100%;
}

.container-tab-content {
  display: flex;

  width: 100%;

  justify-content: space-around;
}

.container-tab-content .content-container {
  flex: 1;
}

.tab-list {
  height: 50px;

  flex: 0.5;

  list-style: none;

  font-size: 18px;

  padding: 0;

  margin: 0;
}

.tabs {
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

  background: #80808013;

  position: relative;

  cursor: pointer;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.active-tabs {
  background: #eeeded;
}

.active-tabs::before {
  content: "";

  display: block;

  position: absolute;

  top: -4px;

  left: 50%;

  transform: translateX(-50%);

  width: calc(100% + 2px);

  height: 4px;

  background: #5893f1;
}

.content {
  /* background-color: #eeeded; */

  display: none;

  padding: 10px;
}

.active-content {
  display: flex;
}

.bgText {
  background-color: #eeeded;

  padding: 10px;

  font-size: 18px;

  font-weight: 600;

  color: #2196f3;
}

/* rashed modification */

.Interactive_main_area {
  display: flex;

  justify-content: space-between;
}

.interactiveApp_Tab {
  background-color: #fff;

  width: 40%;

  padding: 20px;

  border-radius: 10px;

  height: 100%;
}

.interactiveApp_Tab_dot {
  margin-right: 10px;
}

.interactiveApp_Tab_text {
  cursor: pointer;
}

.interactiveApp_Tab_text_active {
  color: #2196f3;

  cursor: pointer;
}

.interactiveApp_Tab_text:hover {
  color: #2196f3;
}

.interactive_view_area {
  width: 55%;

  padding: 20px;

  border-radius: 10px;

  background-color: #fff;
}

.interact_slider {
  position: relative;
}

.interact_slider_left {
  position: absolute;

  left: 0;

  bottom: 40%;

  z-index: 1;
}

.interact_slider_right {
  position: absolute;

  left: 95.38%;

  bottom: 40%;

  z-index: 1;
}
