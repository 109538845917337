.login-component {
  width: 100%;
  height: 100vh;
  text-align: center;
  justify-content: center;
  position: relative;
}
.login-card {
  width: 360px;
  height: inherit;
  position: absolute !important;
  margin: auto;
  left: 0;
  right: 0;
  top: 150px;
}
.login-card .btn {
  width: fit-content;
  margin: auto;
}
.login-card .logo {
  width: 50%;
  margin-top: 40px;
  margin-bottom: 30px;
}
.login-card .title {
  margin-bottom: 30px !important;
  display: block;
}
.login-card button {
  padding: 5px 15px;
  border-radius: 2px;
  border: 1px solid #00c8ff;
  font-weight: 600;
}
.login-card button:hover {
  background-color: #00c8ff;
  color: #fff !important;
  transition: 0.6s;
}

.primary-btn:hover {
  background-color: #00c8ff;
  color: #fff !important;
  transition: 0.6s;
}
.mslogo {
  width: 30px;
  margin-right: 10px;
}

.loginAuthentication {
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
}
.loginAuthentication .card {
  width: 380px;
  margin: auto;
  padding: 25px 30px;
}
.loginAuthentication .brandLogo {
  margin-bottom: 30px;
}
.left {
  float: left;
}
