.searchfilterTitle{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}
.searchfilterSelect{
    width: 100% !important;
}
.searchfilterSelect .ant-select-selector{
    border-radius: 5px !important;
}