.search-area .ant-input-affix-wrapper {
  border: none !important;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 7%);
}
.faqs-content-area .faqTitle {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 600;
  display: block;
}
.faqs-content-area .faqResultCounting {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.faqs-content-area .faqResultCounting .totalResults {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}
.faqs-content-area .faqResultCounting .resultsSort {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  margin-right: 10px;
}
.faqResultCounting {
  margin-bottom: 10px;
}
.faqs-content-area .ant-divider {
  margin: 10px 0px;
}
.matrixBase {
  padding-bottom: 20px;
}
.searchContenResults {
  padding-bottom: 30px;
}

.MatrixBasedTable {
  overflow-x: overlay;
}

* {
  font-weight: revert !important;
}

.red {
  background-color: rgb(250, 54, 81);
}
.yellow {
  background-color: yellow;
}
.green {
  background-color: rgb(130, 235, 130);
}
.blue {
  background-color: rgb(128, 178, 252);
}
.input-filter-value {
  width: 30%;
  margin-left: 10px;
}
.ant-table-tbody > tr > td {
  background: none !important;
}
.right {
  float: right;
}
.ant-table table {
  width: 130% !important;
}
.matrix-button-filter {
  padding: 5px 5px !important;
}
