.searchContent .ant-list-item-meta-title > a {
  font-weight: 600;
}

.searchContent .list-group .list-group-item {
  padding: 10px 0px;
}
.searchContent .list-group .list-group-item a {
  display: flex;
  align-items: center;
}

.searchContent .resultImg {
  width: 120px;
  height: 100px;
}

.searchContent .card {
  padding: 5px 20px;
}

.searchContent .resultContent {
  padding: 0px 10px;
  position: relative;
  margin: 1% 0px;
}

.searchContent .resultContent .resultTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.searchContent .resultContent .resultContents {
  color: #8d8787;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.searchContent .resultContent .resultsBookmarks {
  position: absolute;
  top: 0;
  right: 0;
}

.resultsBookmarks {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2%;
  cursor: pointer;
}

.favourite {
  background-color: yellow;
  color: black;
}

.searchContent .resultContent .resultsTagTimes {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resultsTagTimes .resultsCategory {
  flex: 1;
}

.resultsTagTimes .resultsTag {
  flex: 1;
}

.uploadTime {
  color: #8d8787;
  padding-bottom: 5px;
  font-size: 14px;
  display: block;
  font-weight: 600;
}

.resultContentImage {
  float: left;
  padding: 0 10px;
  height: 200px;
  display: flex;
}
.result-img {
  margin: auto;
  height: 200px !important;
  width: 400px !important;
}
.archBadge {
  background: burlywood;
  margin-left: 10%;
}
