.myCourseDetailsRightAccordionTitle {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  padding-left: 15px;
}
.RightAccordionDetailsList {
  padding: 0;
  list-style: none;
}
.RightAccordionDetailsItem {
  display: inline-block;
  margin-left: 20px;
  color: #373636;
  font-size: 13px;
}
.myCourseDetailsRightAccordionArea .ant-collapse {
  border: none;
}
.myCourseDetailsRightAccordionArea .ant-collapse-content {
  border: none;
}
.myCourseDetailsRightAccordionArea .ant-collapse-item {
  border: none;
  margin-bottom: 2px;
}
.myCourseDetailsRightAccordionArea .ant-collapse-header {
  background-color: #e9f1f7;
  border-radius: 5px;
}
.myCourseDetailsRightAccordionArea .ant-collapse-header:hover {
  background-color: #f2f5f7;
}
.myCourseDetailsRightAccordionHeading {
  display: inline-block;
  /* width: 90%; */
}
.myCourseDetailsRightAccordionHeading .info {
  display: flex;
  justify-content: space-between;
}
.myCourseDetailsRightAccordionHeadingTitle {
  margin: 0;
  font-size: 14px !important;
  color: #121f3e;
}
.myCourseDetailsRightAccordionTime {
  font-size: 13px !important;
  color: #121f3e;
}
.myCourseDetailsRightAccordionTime svg {
  font-size: 10px;
}
.rightAccordionDscList {
  padding-left: 10px;
  margin: 0;
}
.rightAccordionDscItem {
  list-style: none;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.rightAccordionDscItemTitle {
  color: #00c8ff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.rightAccordionDscItemTime {
  font-weight: 500;
  font-size: 13px;
}
.myCourseDetailsRightAccordionMoreButton {
  border: none;
  background-color: #e9f1f7;
  width: 100%;
  border: 5px;
  padding: 8px;
  text-transform: capitalize;
  font-weight: 500;
  cursor: pointer;
  color: #00c8ff;
}
.myCourseDetailsRightAccordionMoreButton:hover {
  background-color: #e1edf7;
}

.sectionLevel {
  font-weight: bold;
}

/* .video-js .vjs-big-play-button {
  top: 45% !important;
  left: 42% !important;
} */
