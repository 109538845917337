
.hoomeHeading{
    display: block;
    font-weight: 500 !important;
}
.home-page{
    padding-bottom: 20px;
}
.videoLibraryArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.videoLibraryArea .more{
    text-decoration: underline !important;
}