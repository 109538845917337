.featured-article{
    margin-bottom: 20px;
}
.featured-article .ant-tabs-nav{
    margin-bottom: 0;
}
.featured-article .featuredTheading{
    background-color: #f5f5f5;
    border-bottom: none !important;
    border-radius: 5px;
}
.featuredTheading th{
    border: none !important;
}
.featured-article .ant-tabs-content-holder{
    margin-top: 10px;
}