.interactivedemoTitle {
  font-size: 15px !important;
  text-transform: capitalize;
}
.interActiveDemoBody {
  margin-top: 20px;
}
.interActiveDemoSearch {
  margin-bottom: 20px;
}
.interActiveDemoSearch .ant-input-affix-wrapper {
  border: none !important;
}
.interActiveDemoCollapse .ant-collapse {
  border: none;
}
.interActiveDemoCollapse .ant-collapse-header {
  background-color: #fff;
  border-radius: 10px;
  font-weight: 600;
}
.interActiveDemoCollapse .ant-collapse-content {
  background-color: #f1f1f1;
  border-radius: 10px;
  border: none;
}
.interActiveDemoCollapse .ant-collapse > .ant-collapse-item {
  border: none !important;
  margin-bottom: 5px;
}
.interActiveDemoCollapse .content {
  padding: 10px;
}
.interActiveDemoCollapse .collapseTitle {
  font-size: 15px !important;
  text-transform: capitalize;
}
.interActiveDemoCollapse .collapseListItem {
  margin-top: 10px;
  list-style: disc;
}
.ussdPan {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-decoration: none !important;
  cursor: pointer !important;
}
