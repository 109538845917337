.quickCall .card {
  padding: 10px 25px;
  background-color: #fff !important;
}
.quickCall .quickCallCollapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quickCallCollapse .quickInCall {
  flex: 1;
}
.quickCallCollapse .quickCallingTime {
  flex: 1;
}
.quickCollapse {
  cursor: pointer;
}
.quickInCall .inCallText {
  font-weight: 600;
  margin-right: 10px;
  color: #00c8ff;
}
.quickInCall .inCall {
  font-weight: 600;
  color: #121f3e;
  font-size: 12px;
}
.quickCallingTime .callTime {
  font-weight: 600;
  color: red;
  font-size: 13px;
}
.quickCallingTime .callTime svg {
  color: gray;
  margin-right: 5px;
}
.quickCollapseContent {
  padding: 20px 10px;
}
.quickCall .ant-collapse-item {
  border: none !important;
}
.quickCall .ant-collapse {
  border: none !important;
}
.quickCall .ant-collapse-header {
  background-color: #fff !important;
  padding: 5px 15px !important;
}
.quickCall .quickCallCollapseBody {
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
}
.quickCallCollapseBody .right .btn {
  background-color: #00c8ff;
  color: #fff;
  margin: 5px;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 600 !important;
  padding: 5px 15px;
}
.quickCallCollapseBody .left .leftContent {
  display: flex;
  align-items: center;
}
.quickCallCollapseBody .left {
  flex: 1;
}
.quickCallCollapseBody .right {
  justify-content: end;
  display: flex;
}
.quickCallCollapseBody .left .callTypes {
  flex: 1;
}
.quickCallCollapseBody .left .startTime {
  flex: 1;
}
.quickCallCollapseBody .left .text {
  text-transform: capitalize;
  font-weight: 600;
  color: #121f3e;
  font-size: 12px;
  margin-right: 10px;
}
.quickCallCollapseBody .left .time {
  font-size: 12px;
}
.quickCall .ant-collapse-content-box {
  padding-top: 25px !important;
}

/* modal  */
.messageModal .ant-modal-close {
  border-radius: 50%;
  background: rgb(245, 244, 244);
  right: -15px;
  top: -15px;
}
.messageModal .ant-modal-footer {
  display: none;
}
.messageModal .messageModalBody .title {
  margin-top: 15px;
  font-size: 15px !important;
  text-transform: capitalize;
}
.messageModal .messageModalBody .radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.messageModalBody .radio .ant-radio-group {
  flex: 0.4;
}
.messageModalBody .radio .ant-input {
  flex: 1;
}
.messageModalBody .btn {
  background-color: #00c8ff;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 600 !important;
  padding: 5px 25px;
  margin: auto;
  display: block;
  margin-top: 30px;
}
.messageModalBody .textarea .ant-input {
  border: none;
  background-color: #f7f7f7;
}
.messageModalBody .callTypes {
  margin-bottom: 10px;
}
.messageModalBody .callTypes .text {
  margin-right: 10px;
  text-transform: capitalize;
  font-weight: 600;
}

.call-info {
  text-align: center;
}
.call-info span {
  font-weight: bold;
}

.current {
  font-weight: bold !important;
}

.row-content {
  width: 100%;
  display: inline-block;
}

.star {
  color: #ff0713;
}

.masking {
  display: block !important;
}
