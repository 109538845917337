.elearningCoursesDetailsBannerCard {
  width: 100%;
  height: 350px;
}
.elearningCoursesDetailsBannerCard .content {
  display: flex;
  width: 100%;
}
.elearningCoursesDetailsBannerCardLeft {
  flex: 2;
  padding: 20px;
}
.elearningCoursesDetailsBannerCardRight {
  flex: 1;
  background-color: #eee;
  border-radius: 5px;
}
.elearningCoursesDetailsBannerCardRight .videoPlayer {
  height: 195px;
}
.elearningCoursesDetailsTitle {
  font-size: 14px !important;
}
.elearningCoursesDetailsDesc {
  color: #8d8787;
  font-size: 14px;
  display: block;
  width: 80%;
  padding-bottom: 5px;
}
.elearningCoursesDetailsPostTime {
  color: #8d8787;
  font-size: 13px;
  font-weight: 500;
  display: block;
}
.elearningCoursesDetailsInstruction {
  color: #8d8787;
  font-size: 13px;
  font-weight: 600;
  display: block;
}
.elearningCoursesDetailsReview {
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
}
.elearningCoursesDetailsReview .ant-rate {
  font-size: 13px;
}
.elearningCoursesDetailsBannerCardLeftBtn {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #e2e1e1;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  background: #f9f9f9;
}
.elearningCoursesDetailsBannerCardRight .buttons {
  text-align: center;
  margin: 10px auto;
}
.elearningCoursesDetailsBannerCardRightbtn {
  border: 1px solid #00c8ff;
  padding: 6px 12px;
  font-size: 14px;
  text-transform: capitalize;
  margin: 5px;
  border-radius: 5px;
  background-color: #00c8ff;
  color: #fff;
  cursor: pointer !important;
}
.elearningCoursesDetailsBannerCardRightbtnEnrolledbtn {
  border: 1px solid #0fbb3f;
  padding: 6px 12px;
  font-size: 14px;
  text-transform: capitalize;
  margin: 5px;
  border-radius: 5px;
  background-color: #0fbb3f;
  color: #fff;
  cursor: pointer !important;
  font-weight: 600;
}
.elearningCoursesDetailsBannerCardRightbtn:hover {
  border: 1px solid #00c8ff;
  background-color: #fff;
  color: #00c8ff;
  transition: 0.7s;
}
.elearningCoursesDetailsBannerCardRight .info {
  padding: 0px 20px;
}
.elearningCoursesDetailsBannerCardRightTitle {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}
.elearningCoursesDetailsBannerCardRightList {
  list-style: none;
  padding: 0;
}
.elearningCoursesDetailsBannerCardRightItem {
  margin-top: 10px;
}
