body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* colors */

.bgc-light {
  background-color: #fff;
}

.bgc-light-100 {
  background-color: #fafafc;
}

.bgc-primary {
  background-color: #00c8ff;
  color: #fff;
}

.bgc-secondary {
  background-color: #2dbdb6;
}

.bgc-gray {
  background-color: #414042;
}

.bgc-gray-100 {
  background-color: rgb(231, 235, 245);
}

.primary-text {
  color: #00c8ff !important;
}

.gray-text {
  color: #414042 !important;
}

.gray-text-200 {
  color: #686669 !important;
}

.gray-100-text {
  color: rgb(231, 235, 245) !important;
}

.primary-btn {
  background-color: #00c8ff !important;
  color: #fff !important;
}

.gray-btn {
  background-color: #414042 !important;
  color: #fff !important;
}

.gray-100-btn {
  background-color: rgb(231, 235, 245) !important;
  color: #414042 !important;
}

.shadow {
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 7%) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.card {
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: block;
  background-color: #fff;
  color: var(--greyclr);
  z-index: 0;
  border: none !important;
  position: relative;
  box-shadow: 0 3px 6px 0 rgb(44 40 40 / 7%);
}

/* .card:hover {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  transition: 0.7s;
} */

.btn {
  box-shadow: 0 3px 6px 0 rgb(125 125 125 / 16%);
  font-size: 0.8rem;
}

.btn:hover {
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%), 0 1px 7px 0 rgb(0 0 0 / 12%),
    0 3px 1px -1px rgb(0 0 0 / 20%);
  transition: 0.3s;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.font-bold {
  font-weight: 600;
}

.font-normal {
  font-weight: 300;
}

.font-light {
  font-weight: 100;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.border-none-btn {
  border: none !important;
  background-color: transparent !important;
}

.list-style-none {
  list-style: none !important;
}

/* text  */

h1 {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

h2 {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

p {
  font-size: 0.9rem !important;
  font-weight: 300 !important;
  margin: 0px !important;
}

a {
  text-decoration: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00c8ff !important;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #00c8ff !important;
}

.ant-tabs-ink-bar {
  background-color: #00c8ff !important;
}

.ant-tabs-tab:hover {
  color: #00c8ff !important;
}

.ant-pagination-item-active a {
  color: #00c8ff !important;
}

.ant-pagination-item-active {
  border-color: #00c8ff !important;
}

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
  border-color: #00c8ff !important;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #00c8ff !important;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #00c8ff !important;
  border-color: #00c8ff !important;
}

.ant-tag {
  border-radius: 20px !important;
  background-color: #f9f9f9 !important;
  border: 1px solid rgb(255, 254, 254) !important;
  padding: 0px 12px !important;
  color: rgb(73, 73, 73);
  font-weight: 600;
}
.ant-tag a {
  color: rgb(73, 72, 72);
  font-weight: 600 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #00c8ff !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #00c8ff !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #00c8ff !important;
  box-shadow: none !important;
}

.ant-divider {
  margin: 5px 0px !important;
}

/* customScrollbar  */

.customScrollbar::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Handle */

/* .customScrollbar::-webkit-scrollbar-thumb {
  background: rgb(221, 220, 220); 
  border-radius: 10px;
} */

/* Handle on hover */

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(221, 220, 220);
  border-radius: 10px;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  color: rgba(0, 0, 0, 0.45) !important;
}
.ant-checkbox-group .ant-checkbox-group-item {
  width: 50%;
  margin: 5px 0px !important;
}
.ant-rate {
  color: #e59830 !important;
}
