.myConfusionsheading {
  background-color: rgba(0, 0, 0, 0.075);
}
.confusionsTitle {
  font-size: 13px;
  font-weight: 600;
}
.myConfusionsheading th {
  font-size: 13px;
}
.myConfusionsTable .table td {
  font-size: 13px;
}
.loadMoreBtn {
  border: none;
  margin-top: 30px;
  width: 130px;
  font-size: 13px;
  text-transform: uppercase;
  padding: 7px 10px;
  margin: auto;
  border-radius: 5px;
  background-color: #e2e2e2 !important;
  color: #2196f3;
  font-weight: 600;
  display: block;
}

.myConfusionsDetailsContents {
  display: flex;
  justify-content: space-between;
}
.myConfusionsDetailsListLeft {
  margin-top: 10px;
  list-style: none;
}
.myConfusionsDetailsListLeft li {
  margin-bottom: 5px;
  font-size: 14px;
}
.myConfusionsDetailsListLeft li svg {
  margin-right: 5px;
}
.confusionsDetailsPill {
  background-color: rgb(144, 247, 169);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 13px;
}
.confusionsDetailsPillUnanswred {
  background-color: red;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 13px;
}
.myConfusionsDetailsContents .left {
  flex: 1;
}
.myConfusionsDetailsContents .right {
  text-align: right;
}
.myConfusionsDetailsListRight {
  margin-top: 10px;
  list-style: none;
}
.myConfusionsDetailsListRight li {
  margin-bottom: 5px;
  font-size: 13px;
  color: gray;
}
.confusionsDetailsBtn {
  padding: 3px 5px;
  border: none;
  background-color: #00c8ff;
  width: 120px;
  border-radius: 5px;
  color: #fff;
  margin-top: 10px;
  float: right;
}
.myConfusionsDetailsTextarea {
  border: none !important;
  background-color: #f0f2f5;
}
.myConfusionsDetailsTextarea .ant-input-focused {
  box-shadow: none !important;
}
.myConfusionsTable .ant-divider {
  margin: 10px 0px;
}
.searchBar {
  width: 20%;
  float: right;
}
