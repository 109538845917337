.video-cart {
  padding-top: 0;
  margin-bottom: 30px;
  position: relative;
}
.video-cart .ant-list-item {
  justify-content: start;
}
.video-cart .description {
  width: 70%;
  display: inline-block;
  padding: 0px 10px;
}
.video-cart .description a {
  font-weight: 600;
  font-size: 13px;
  color: #000000d9;
}
.video-cart .description a:hover {
  color: purple;
}

.video-cart .description h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.video-cart .description .videoText {
  font-size: 13px;
  color: #00000073;
}
.video-cart .description span {
  font-size: 13px;
  color: #00000073;
}
.video-cart .description span {
  font-weight: 400 !important;
}
.video-cart .description .videoUploadTime {
  display: block;
  font-weight: 600 !important;
  font-size: 12px;
}
.video-cart .thumbnails {
  position: relative;
  background-color: #000;
  width: 28%;
  height: 150px;
  display: inline-block;
  border-radius: 5px;
}
.video-cart .thumbnails a {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
}
.video-cart .thumbnails img {
  opacity: 0.9;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.video-cart .thumbnails img:hover {
  opacity: 0.7;
}
.video-cart .thumbnails a svg {
  font-size: 34px;
  color: #fff;
}
.loardMore {
  border: none !important;
  width: 120px;
  display: block;
  margin: auto;
  background-color: #dadbdd;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #2196f3;
  padding: 7px 10px;
}
.video-favourite {
  position: relative;
  top: 0;
  right: 0;
  margin: 2%;
}
.favourite {
  background-color: yellow;
  color: black;
}
.resultsBookmarks {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2%;
  cursor: pointer;
}
.favIconClass {
  position: relative;
  top: 0;
  right: 0;
}
