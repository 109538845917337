.related-video-list {
  padding-top: 0;
}
.related-video-list .ant-list-item {
  justify-content: start;
}
.related-video-list .description {
  width: 70%;
  display: inline-block;
  padding: 0px 10px;
}
.related-video-list .description a {
  font-weight: 600;
  font-size: 13px;
  color: #000000d9;
}
.related-video-list .description a:hover {
  color: purple;
}

.related-video-list .description h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.related-video-list .description span {
  font-size: 13px;
  color: #00000073;
}
.related-video-list .description span {
  font-weight: 400 !important;
}
.related-video-list .description .videoUploadTime {
  display: block;
  font-weight: 600 !important;
  font-size: 12px;
}
.related-video-list .thumbnails {
  position: relative;
  background-color: #000;
  width: 40%;
  height: 100px !important;
  display: inline-block;
  border-radius: 5px;
}
.related-video-list .thumbnails a {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
}
.related-video-list .thumbnails img {
  opacity: 0.8;
}
.related-video-list .thumbnails img:hover {
  opacity: 0.7;
}
.related-video-list .thumbnails a svg {
  font-size: 30px;
  color: #fff;
}
