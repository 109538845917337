.faqViewTagCats {
  display: flex;
  margin: 10px 0px;
}
.faqViewTagCatsTitle {
  text-transform: capitalize;
  font-size: 14px;
  margin-right: 5px;
}
.faqViewTagCats .ant-tag {
  font-size: 12px;
  font-weight: 600;
}
.faqViewPostTime {
  color: #8d8787;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0px;
}
.faqViewTitle {
  font-size: 16px;
  line-height: 24px;
}
.faqViewDescription {
  color: #363535;
  font-size: 14px;
  line-height: 24px;
  padding: 2%;
  overflow-x: auto;
}
.faqviewTranslatebutton {
  width: 120px;
  border: none;
  background-color: #00c8ff;
  color: #fff;
  font-weight: 600 !important;
  display: block;
  border-radius: 5px;
}

/* faqPreviousQuestions */
.faqPreviousQTitle {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin-top: 25px;
}
.faqPreviousQuestions .ant-divider {
  margin: 5px 0px 10px;
}
.faqPreviousQandAns {
  padding: 0;
}
.faqPreviousQandAns .questionTime {
  display: block;
  margin-bottom: 10px;
}
.faqPreviousQandAns .qandAns {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}
.faqPreviousQandAns .qandAnsTime {
  float: right;
  color: #8d8787;
  font-weight: 400;
  font-size: 13px;
}
.faqPrevQPostTextBox {
  border: none;
  margin-bottom: 10px;
  background-color: #fafafc;
}
.faqPrevQPostbutton {
  border-radius: 5px;
  width: 120px;
  border: none;
  background-color: #00c8ff;
  color: #fff;
  font-weight: 600 !important;
  margin: auto;
  display: block;
  text-transform: capitalize;
}
.faqButtonGrp {
  display: flex;
  text-align: center;
  justify-content: center;
  font-weight: 600 !important;
}
.file_button {
  margin-left: 1%;
  border-radius: 5px;
}
.featureImage {
  width: 100% !important;
  height: auto;
}
