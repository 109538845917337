.siteMapBody .mapList {
  margin-top: 30px;
}
.siteMapBody {
  padding-bottom: 30px;
}
.siteMapBody .mapListItem {
  list-style: none;
  margin: 20px 0px;
}
.siteMapBody .mapListItem .ListItemHeading {
  font-weight: 600;
  font-size: 16px;
  color: #121f3e;
}
.siteMapBody .mapListItem .ListItemHeading svg {
  margin-right: 5px;
  font-size: 18px;
}
.siteMapBody .mapListInner {
  margin-top: 5px;
}
.siteMapBody .mapListInner .ListItemInnerHeading {
  margin: 7px 0px;
  font-size: 14px;
  color: #121f3e;
  font-weight: 400;
}
.sitemapLink {
  text-decoration: none !important;
  color: inherit;
  font-weight: unset;
}
