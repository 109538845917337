.slickSliderVideo{
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    padding-bottom: 20px;
}

.slickSliderVideo .swiper-container {
    width: 100%;
    height: 100%;
  }
  
.slickSliderVideo .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 150px !important;
    position: relative;
    margin: 0px 5px !important;
    border-radius: 10px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #000;
}

.slickSliderVideo .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .8;
    border-radius: 10px;
}
.slickSliderVideo .swiper-slide img:hover{
    opacity: .6;
    transition: .6s;
}
.slickSliderVideo .swiper-slide a{
    position: absolute;
} 

.slickSliderVideo .swiper-slide a svg{
    font-size: 50px;
    color: #fff;
}
.slickSliderVideo .swiper-button-next:after{
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
}
.slickSliderVideo .swiper-button-prev:after{
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #fff !important;
}
.slickSliderVideo .swiper-button-next{
    width: 30px !important;
    height: 30px !important;
    background: rgba(8, 8, 8, 0.5);
    border-radius: 50%;
    margin-top: -5px;
    
}
.slickSliderVideo .swiper-button-prev{
    width: 30px !important;
    height: 30px !important;
    background: rgba(8, 8, 8, 0.5);
    border-radius: 50%;
    margin-top: -5px;
}