.video-page .videoSectionTitle{
    font-size: 13px !important;
    text-transform: capitalize;
    font-weight: 600;
}
.video-page .ant-divider{
    margin: 5px 0px;
}
.video-page .contentCount{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.video-page .contentCount .contentText{
    font-weight: 600;
    font-size: 12px;
    color: gray;
}
.video-page .contentCount .sortby{
    font-weight: 600;
    font-size: 12px;
    color: gray;
    text-transform: capitalize;
    margin-right: 5px;
}