.notificationsPage {
  padding-bottom: 30px;
}
.notificationsList {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.notificationsCard {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.notificationsCard .left {
  width: 40px;
  text-align: center;
}
.notificationsCard .mid {
  flex: 1;
  cursor: pointer;
}
.notificationsCard .right {
  flex: 0.2;
}
.notificationsDate {
  margin-top: 30px;
}

.readme {
  font-weight: normal !important;
}
