
.head-marquee{
    margin-bottom: 15px;
}
.marqueeArea{
  border-radius: 10px;
}
.marqueeArea .overlay{
  background-color: #E6EEF4 !important;
  --gradient-width: auto !important;
}
.marqueeArea .overlay::before, .overlay::after{
  background: #E6EEF4 !important;
}
.marqueeArea .marquee{
  padding: 7px;
}