.myCoursesCardThumbnails {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  background-color: #000;
  position: relative;
}
.myCoursesCardThumbnails img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0.7;
}
.myCoursesCardThumbnails img:hover {
  opacity: 0.9;
}
.myCoursesCardPlaybtn {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 45px;
  color: aliceblue;
}
.myCoursesCardTitle {
  font-weight: 600;
  font-size: 14px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.myCoursesCardInstructor {
  font-weight: 400;
  font-size: 13px;
  margin-top: 5px;
  color: #373636;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.myCoursesCardDesc {
  font-weight: 400;
  font-size: 13px;
  margin-top: 0px;
  color: #373636;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.myCoursesCardRatingPStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.myCoursesCardRatingAndTitle {
  text-align: right;
}
.myCoursesCardProgrssStatus {
  font-weight: 400;
  font-size: 13px;
  margin-top: 0px;
  color: #373636;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.myCoursesCardRatingTitle {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0px;
  color: #373636;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
  float: right;
}
.myCoursesCardRating {
  display: block;
}
.myCoursesCardRating .ant-rate {
  font-size: 14px;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #bbb;
  height: 5px !important;
}
.ant-progress-inner {
  background-color: #eee;
}

.btn-dwn {
  margin-top: 5px;
}

.enroll-btn {
  background: #309f66 !important;
}

.enroll-btn span {
  font-weight: bold;
}
