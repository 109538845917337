.allCoursDescriptionTitle{
    margin-top: 25px;
    font-size: 14px !important;
}
.allCoursDescriptionTitle2{
    margin-top: 0px;
    font-size: 13px !important;
    color: #8D8787;
}
.allCoursDescriptionText{
    color: #373636;
    font-size: 14px;
    padding-bottom: 10px;
}
.allCoursDescriptionItem{
    color: #373636;
    font-size: 14px;
    margin-top: 10px;
}
.allCoursDescriptionList{
    margin-bottom: 35px;
}
.allCoursDescriptionThumbnails{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.allCoursDescriptionThumbnails .img{
    width: 200px;
    height: 180px;
}
.allCoursDescriptionThumbnails .img img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.allCoursDescriptionThumbnails .list{
    list-style: none;
}
.allCoursDescriptionThumbnails .item{
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
}
.allCoursDescriptionFeedback{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.allCoursDescriptionFeedback .left{
    flex: 1;
    align-items: center;
    text-align: center;
}
.allCoursDescriptionRatingPoint{
    display: block;
    margin-top: 10px;
    font-weight: 600;
    font-size: 30px;
}
.allCoursDescriptionRatingStar{
    color: #E59830;
    font-weight: 500;
}
.allCoursDescriptionRatingStar .ant-rate{
    font-size: 14px;
    display: block;
}
.allCoursDescriptionFeedback .right{
    flex: 3;
    align-items: center;
}
.allCoursDescriptionFeedback .right ul{
    padding: 0;
    margin: 0;
    list-style: none;
}

.allCoursDescriptionFeedback .right ul .rightItem{
    margin-top: 10px;
    display: flex;
}
.allCoursDescriptionFeedback .right .ant-progress{
    display: inline-block;
    flex: 3;
}
.allCoursDescriptionProgressStar {
    padding-left: 10px;
    flex: 1;
    text-align: right;
}
.allCoursDescriptionProgressStar .ant-rate{
    font-size: 14px;
    padding-left: 10px;
}
.allCoursDescriptionSearchFilter{
    padding-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    width: 100%;
}
.allCoursDescriptionSearchFilter .left{
    flex: 2;
}
.allCoursDescriptionSearchFilter .left .ant-input-affix-wrapper{
    padding: 10px 12px;
    border: none;
    border-radius: 5px;
}
.allCoursDescriptionSearchFilter .right{
    flex: 1;
    text-align: right;
}
.allCoursDescriptionSearchFilter .right .ant-select-selector{
    border-radius: 5px;
    height: 42px;
    padding: 5px 12px;
}
.allCoursDescriptionRatingList{
    margin-bottom: 60px;
}
.allCoursDescriptionRatingList .istItem{
    display: flex;
    align-items: center;
}
.allCoursDescriptionRatingList .istItem .img{
    width: 80px;
    height: 50px;
}
.allCoursDescriptionRatingList .istItem .img img{
    width: 100%;
    height: 100%;
}
.allCoursDescriptionRatingList .istItem .info{
    flex: 1;
}
.allCoursDescriptionRatingList .listItemTitle{
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 500;
}
.allCoursDescriptionRatingList .listItemRatings{
    font-size: 13px;
    margin-bottom: 0px;
    color: #8D8787;
    display: block;
}
.allCoursDescriptionRatingList .listItemRatings .ant-rate{
    font-size: 13px;
    margin-right: 10px;
}
.allCoursDescriptionRatingList .listItemDes{
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}
.allCoursDescriptionRatingList .listItemQ{
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
    display: block;
}
.allCoursDescriptionLoadMoreButton{
    text-transform: capitalize;
    margin-bottom: 50px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
    color: #6400Aa;
    background-color: #DADBDD;
}