.featureSlider .swiper-button-next {
  border-radius: 50%;
  background-color: #00c8ff;
  width: 30px;
  height: 30px;
}
.featureSlider .swiper-button-next:hover {
  background-color: #8a05e9;
  transition: 0.6s;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-size: 15px;
  color: #fff !important;
}

.featureSlider .swiper-button-prev {
  border-radius: 50%;
  background-color: #00c8ff;
  width: 30px;
  height: 30px;
  left: 0;
}
.featureSlider .swiper-button-prev:hover {
  background-color: #8a05e9;
  transition: 0.6s;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-size: 15px;
  color: #fff !important;
}
